import { Layout, Typography } from 'antd';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  BsFacebook,
  BsInstagram,
  BsTwitter,
  BsGithub,
  BsDribbble,
} from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
// import Netkathir from '../assets/images/Netkathir.png';
import { Message } from '../utils/antd-components/index';
import { GiDiamondsSmile } from 'react-icons/gi';

export default function FooterCom() {
  const { t } = useTranslation();
  const { Footer } = Layout;
  const [clickCount, setClickCount] = useState(0);

  const handleTeamClick = () => {
    setClickCount((prevCount) => prevCount + 1);
  };
  return (
    <Footer className="border border-t-8 border-green-500">
      <div className="w-full max-w-7xl mx-auto">
        <div className="w-full justify-between flex"></div>
        <div className="w-full sm:flex sm:items-center sm:justify-between">
          <div className="gap-4">
            <Link
              href="https://Swirlz.com"
              target="_blank"
              className="self-center whitespace-nowrap text-lg sm:text-xl font-semibold dark:text-white"
            >
              {/* <img
                src={Netkathir}
                alt="user"
                className="block mx-auto w-1/2 mb-5"
              /> */}
            </Link>
            <Typography.Text>
              <Link href="https://swirlz.com" target="_blank">
                {t('swirlz')}
              </Link>{' '}
              | &copy;{new Date().getFullYear()}
            </Typography.Text>
          </div>

          {/* <p className="text-sm mb-5 mt-5 py-4 text-start text-white-500">
            <b>{t('address')}</b>
            <br />
            {t('Address')}
            <br />
            {t('Address1')}
            <br />
            {t('Address2')}
            <br />
          </p> */}
          <p className="text-sm mb-5 mt-5 py-4 text-start text-white-500">
            {/* <b> {t('email')}</b> {t('admin-swirlz-com')}
            <br />
            <b>{t('Phone')}</b> 0413-2233944
            <br /> */}
            <Link to={'/privacy-policy'}>{t('privacy-policy')}</Link>
            <br />
            <Link to={'/terms-and-conditions'}>{t('Terms')}</Link>
          </p>
          <div className="flex gap-6 sm:mt-0 mt-4 sm:justify-center">
            <Link href="https://www.facebook.com/Swirlz/" target="_blank">
              <BsFacebook />
            </Link>
            <Link href="https://www.instagram.com/Swirlz/" target="_blank">
              <BsInstagram />
            </Link>
            <Link href="https://www.instagram.com/Swirlz/" target="_blank">
              <BsTwitter />
            </Link>
            <Link href="https://www.instagram.com/Swirlz/" target="_blank">
              <BsGithub />
            </Link>
            <Link href="https://www.instagram.com/Swirlz/" target="_blank">
              <BsDribbble />
            </Link>
          </div>
        </div>
        <div className="w-full sm:flex sm:items-center sm:justify-center">
          <span className="mr-1">{t('made-with-by')} </span>
          <span onClick={handleTeamClick}>{t('react-team')} </span>
        </div>
      </div>

      {clickCount === 4 && (
        <Message
          successMessage={
            <>
              {t('built-by-ram-and-sundar')}
              <GiDiamondsSmile color="red" />
            </>
          }
          type="success"
          position="bottom"
          icon={<GiDiamondsSmile color="red" />}
        />
      )}
    </Footer>
  );
}
