import { useTranslation } from 'react-i18next';
import { TypewriterEffectSmooth } from '../../utils/UiComponents/typewriter-effect.tsx';
import { LampContainer } from '../../utils/UiComponents/lamp-effect.tsx';
import { FloatButton } from '../../utils/antd-components/index.tsx';

export default function Home() {
  const { t } = useTranslation();

  const words = [
    {
      text: t('welcome'),
      className: 'text-white dark:text-white',
    },
    {
      text: 'to',
      className: 'text-white dark:text-white',
    },
    {
      text: t('swirlz'),
      className: 'text-green-500 dark:text-#84cc16-700',
    },
    {
      text: t('recycle'),
      className: 'text-white dark:text-white',
    },
    {
      text: t('admin'),
      className: 'text-blue-500',
    },
    {
      text: 'Panel.',
      className: 'text-blue-500 dark:text-blue-500',
    },
  ];

  return (
    <>
      <FloatButton.BackTop />
      <LampContainer>
        <>
          {/* <p className="text-lg md:text-7xl font-normal pb-4 text-center bg-clip-text text-transparent bg-gradient-to-b from-neutral-100 to-neutral-300">
            {t('built-by-netkathir-technologies')}{' '}
          </p> */}
          <TypewriterEffectSmooth words={words} />
          <p className="text-xs md:text-xl font-normal text-center text-neutral-400 mt-4 max-w-lg mx-auto">
            {t('built-by-react-team-nkt')}{' '}
          </p>
        </>
      </LampContainer>
    </>
  );
}
