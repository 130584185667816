/* eslint-disable no-unused-vars */
const PATHS = {
  ADMIN: {
    ADD: '/adminom/deities/add-deities',
    POSTS: '/admin/getposts',
  },
  COMMENTS: {
    GET: '/comments/get-comments',
    UPDATE: '/comments/update-comments',
    ADD: '/comments/add-comments',
    LIKE_COM: '/comments/like-comments',
    DELETE: '`/comments/delete-comments',
  },
  SEARCH: {
    GET: '/search/get',
  },
  PRICING: {
    GET: '/pricing/get-pricing',
    DELETE: '/pricing/delete-pricing',
    UPDATE: '/pricing/update-pricing/',
    ADD: '/pricing/add-pricing/',
  },
  AUTH: {
    REGISTER: '/auth/register',
    VER_EMAIL: '/auth/verify-email',
    RES_CODE: '/auth/resend-code',
    LOGIN: '/auth/login',
  },
  COGNITO: {
    REGISTER: '/auth/register',
    VER_EMAIL: '/auth/verify-email',
    RES_CODE: '/auth/resend-code',
    LOGIN: '/auth/',
  },
  WISHLIST: {
    ADD: '/wishlist/add-wishlist',
    LIST: '/wishlist/list-wishlist',
    DELETE: '/wishlist/delete-wishlist',
  },
  PROFILE: {
    ADD: '',
    GET: '',
    DELETE: '',
    UPDATE: '',
  },
  POSTS: {
    RECPOST: '/posts-recent',
    GET: '/posts/',
    LIST: '/posts',
    INDIVIDUAL: '/posts/',
    UPDATE: '/posts/',
    AUTH: '/v1/cognito/login',
    ADD: '/posts',
    DELETE: '/posts/',
    POST: '/posts',
  },
  CATEGORIES: {
    ADD: '/categories/',
    GET: '/categories',
    GETLIST: '/categories-limit',
    DELETE: '/categories/',
    UPDATE: '/categories/',
  },
  IMAGE: {
    POST: '/files/',
    DELETE: '/files/',
    ADD: ' /files/ ',
  },
  USERS: {
    INDIVIDUAL: '/user/get-user-individual',
    CREATE: '/users',
    GET: '/users',
    DELETE: '/users',
    UPDATE: '/users',
    ADMINUPDATE: '/user/update-user',
  },
  ROLES: {
    ADD: '/roles',
    GET: '/roles',
    DELETE: '/roles',
    UPDATE: '/roles',
  },
  SUBSCRIPTION: {
    ADD: '/subscriptions/add-subscriptions/',
    GET: '/subscriptions/get-subscriptions',
    DELETE: '/subscriptions/delete-subscriptions',
    POST: ' ',
    UPDATE: '/subscriptions/update-subscriptions',
  },
  SETTINGS: {
    GET: '  ',
    DELETE: ' ',
    POST: '/auth/changepassword',
    UPDATE: ' ',
  },
  PAYMENTS: {
    GET: '/payments/get-transaction',
    DELETE: ' ',
    CREATEINTENT: '/payments/create-payment-intent',
    CREATETRANSACTION: '/payments/create-transaction',
    UPDATE: ' ',
  },
};

export default PATHS;
