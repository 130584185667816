/* eslint-disable no-unused-vars */
import { Routes, Route } from 'react-router-dom';
import { useState, Suspense, lazy } from 'react';
import { Skeleton } from '../utils/antd-components/index.tsx';
import Home from '../pages/Home/Home.jsx';
import routeswirlz from './routes.json';

const About = lazy(() => import('../pages/OtherPages/About.jsx'));
const SignIn = lazy(() => import('../pages/Authentications/SignIn.jsx'));
const SignUp = lazy(() => import('../pages/Authentications/SignUp.jsx'));
const UserVerify = lazy(
  () => import('../pages/Authentications/UserVerify.jsx'),
);
const ResetPassword = lazy(
  () => import('../pages/Authentications/ResetPassword.jsx'),
);
const UserResetPassword = lazy(
  () => import('../pages/Authentications/UserResetPassword.jsx'),
);
const CreateCategory = lazy(
  () => import('../pages/Create/CreateCategories.jsx'),
);
const UpdateCategory = lazy(
  () => import('../pages/Update/UpdateCategories.jsx'),
);
const UpdateRole = lazy(() => import('../pages/Update/UpdateRole.jsx'));
const CreateRole = lazy(() => import('../pages/Create/CreateRole.jsx'));
const CreateSubscription = lazy(
  () => import('../pages/Create/CreateSubscription.jsx'),
);
const UpdateSubscription = lazy(
  () => import('../pages/Update/UpdateSubscription.jsx'),
);
const CreateUser = lazy(() => import('../pages/Create/CreateUser.jsx'));
const UpdateUser = lazy(() => import('../pages/Update/UpdateUser.jsx'));
const DashUsers = lazy(
  () => import('../pages/SidebarPages/Users/DashUsers.jsx'),
);
import OnlyAdminPrivateRoute from '../components/OnlyAdminPrivateRoute.jsx';
import CreatePost from '../pages/Create/CreatePost.jsx';
import UpdatePost from '../pages/Update/UpdatePost.jsx';
import PostPage from '../pages/OtherPages/PostPage.jsx';
import Search from '../pages/OtherPages/Search.jsx';
import ViewWishlist from '../pages/OtherPages/ViewWishlist.jsx';
import SubscriptionPage from '../pages/PaymentPages/SubscriptionPage.jsx';
import PaymentPage from '../pages/PaymentPages/PaymentPage.jsx';
import SuccessPage from '../pages/OtherPages/SuccessPage.jsx';
import AuthOutlet from '@auth-kit/react-router/AuthOutlet';
import OrderSummaryPage from '../pages/OtherPages/OrderSummaryPage.jsx';
import PrivacyPolicy from '../pages/OtherPages/PrivacyPolicy.jsx';
import TermsAndConditions from '../pages/OtherPages/TermsAndConditions.jsx';
import PaymentSuccessPage from '../pages/PaymentPages/PaymentSuccessPage.jsx';
import PaymentFailedPage from '../pages/PaymentPages/PaymentFailedPage.jsx';
import MaintenancePage from '../pages/SpecialPages/MaintenancePage.jsx';
import DashProfile from '../pages/SidebarPages/Profile/DashProfile.jsx';
import DashPosts from '../pages/SidebarPages/Post/DashPosts.jsx';
import DashSettings from '../pages/SidebarPages/Settings/DashSettings.jsx';
import DashTransactions from '../pages/SidebarPages/Transactions/DashTransactions.jsx';
const DashCategories = lazy(
  () => import('../pages/SidebarPages/Categories/DashCategories.jsx'),
);
const Dashboard = lazy(
  () => import('../pages/SidebarPages/Dashboard/Dashboard.jsx'),
);
const DashRoles = lazy(
  () => import('../pages/SidebarPages/Roles/DashRoles.jsx'),
);
const DashSubscription = lazy(
  () => import('../pages/SidebarPages/Subscription/DashSubscription.jsx'),
);

export default function App() {
  const [maintenanceEnabled] = useState(false);

  if (maintenanceEnabled) {
    return <MaintenancePage />;
  }
  return (
    <Routes>
      /
      <Route
        path={routeswirlz.signIn}
        element={
          <Suspense fallback={<Skeleton />}>
            <SignIn />
          </Suspense>
        }
      />
      {/* <Route
        path={routeswirlz.signUp}
        element={
          <Suspense fallback={<Skeleton />}>
            <SignUp />
          </Suspense>
        }
      />
      <Route
        path={routeswirlz.userVerify}
        element={
          <Suspense fallback={<Skeleton />}>
            <UserVerify />
          </Suspense>
        }
      />
      <Route
        path={routeswirlz.resetPassword}
        element={
          <Suspense fallback={<Skeleton />}>
            <ResetPassword />
          </Suspense>
        }
      /> */}
      {/* <Route
        path={routeswirlz.userResetPassword}
        element={
          <Suspense fallback={<Skeleton />}>
            <UserResetPassword />
          </Suspense>
        }
      />
      <Route
        path={routeswirlz.dashSubscriptions}
        element={
          <Suspense fallback={<Skeleton />}>
            <DashSubscription />
          </Suspense>
        }
      />
      <Route path={routeswirlz.search} element={<Search />} />*/}
      <Route path={routeswirlz.home} element={<Home />} />
      <Route
        path={routeswirlz.privacyPolicy}
        element={
          <Suspense fallback={<Skeleton />}>
            <PrivacyPolicy />
          </Suspense>
        }
      />
      <Route
        path={routeswirlz.terms}
        element={
          <Suspense fallback={<Skeleton />}>
            <TermsAndConditions />
          </Suspense>
        }
      />
      <Route element={<AuthOutlet fallbackPath={routeswirlz.signIn} />}>
        <Route path={routeswirlz.settings} element={<DashSettings />} />
        <Route path={routeswirlz.posts} element={<DashPosts />} />
        <Route path={routeswirlz.orders} element={<DashTransactions />} />
        <Route path={routeswirlz.profile} element={<DashProfile />} />
        <Route path={routeswirlz.createPost} element={<CreatePost />} />
        <Route path={routeswirlz.updatePost} element={<UpdatePost />} />
        <Route
          path={routeswirlz.subscriptions}
          element={<SubscriptionPage />}
        />
        <Route path={routeswirlz.payment} element={<PaymentPage />} />
        <Route path={routeswirlz.success} element={<SuccessPage />} />
        <Route path={routeswirlz.viewWishlist} element={<ViewWishlist />} />
        <Route
          path={routeswirlz.paymentSuccess}
          element={<PaymentSuccessPage />}
        />
        <Route
          path={routeswirlz.paymentFailed}
          element={<PaymentFailedPage />}
        />
        <Route path={routeswirlz.orderSummary} element={<OrderSummaryPage />} />
      </Route>
      <Route element={<OnlyAdminPrivateRoute />}>
        <Route
          path={routeswirlz.roles}
          element={
            <Suspense fallback={<Skeleton />}>
              <DashRoles />
            </Suspense>
          }
        />
        <Route
          path={routeswirlz.dashboard}
          element={
            <Suspense fallback={<Skeleton />}>
              <Dashboard />
            </Suspense>
          }
        />
        <Route
          path={routeswirlz.categories}
          element={
            <Suspense fallback={<Skeleton />}>
              <DashCategories />
            </Suspense>
          }
        />
        <Route
          path={routeswirlz.createCategory}
          element={
            <Suspense fallback={<Skeleton />}>
              <CreateCategory />
            </Suspense>
          }
        />
        <Route
          path={routeswirlz.updateCategory}
          element={
            <Suspense fallback={<Skeleton />}>
              <UpdateCategory />
            </Suspense>
          }
        />
        <Route
          path={routeswirlz.createRole}
          element={
            <Suspense fallback={<Skeleton />}>
              <CreateRole />
            </Suspense>
          }
        />
        <Route
          path={routeswirlz.updateRole}
          element={
            <Suspense fallback={<Skeleton />}>
              <UpdateRole />
            </Suspense>
          }
        />
        <Route
          path={routeswirlz.createSubscription}
          element={
            <Suspense fallback={<Skeleton />}>
              <CreateSubscription />
            </Suspense>
          }
        />
        <Route
          path={routeswirlz.updateSubscription}
          element={
            <Suspense fallback={<Skeleton />}>
              <UpdateSubscription />
            </Suspense>
          }
        />
        <Route
          path={routeswirlz.users}
          element={
            <Suspense fallback={<Skeleton />}>
              <DashUsers />
            </Suspense>
          }
        />
        <Route
          path={routeswirlz.createUser}
          element={
            <Suspense fallback={<Skeleton />}>
              <CreateUser />
            </Suspense>
          }
        />
        <Route
          path={routeswirlz.updateUser}
          element={
            <Suspense fallback={<Skeleton />}>
              <UpdateUser />
            </Suspense>
          }
        />
      </Route>
      <Route path="/post/:postSlug" element={<PostPage />} />
    </Routes>
  );
}
