/* eslint-disable no-unused-vars */
import { Menu, Layout } from 'antd';
import {
  HiUser,
  HiArrowSmRight,
  HiDocumentText,
  HiOutlineUserGroup,
  HiChartPie,
} from 'react-icons/hi';
// import { GiPriceTag } from 'react-icons/gi';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { signoutSuccess } from '../redux/user/userSlice.js';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import { useNavigate } from 'react-router-dom';
import { MdAdminPanelSettings } from 'react-icons/md';
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { LuCrown } from 'react-icons/lu';
import { CiSettings } from 'react-icons/ci';
// import { MdAttachMoney } from 'react-icons/md';
import { Dropdown } from 'antd';
import { IoMenu } from 'react-icons/io5';

export default function DashSidebar() {
  const { Sider } = Layout;
  const signOut = useSignOut();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const authUser = useAuthUser();
  const roles = authUser?.rolesType;
  const userId = authUser?._id;
  const [collapsed, setCollapsed] = useState(false);
  const handleSignout = async () => {
    try {
      signOut();
      localStorage.setItem('token', '');
      dispatch(signoutSuccess());
      navigate('/sign-in');
    } catch (error) {
      console.log(error.message);
    }
  };

  function getItem(key, icon, title, label, children) {
    return {
      key,
      icon,
      title,
      label,
      children,
    };
  }

  let menuItems = [];
  const adminMenuItems = [
    getItem(
      'dashboard',
      <HiChartPie />,
      t('dashboard'),
      <Link to="/dashboard">{t('dashboard')}</Link>,
    ),
    getItem(
      'roles',
      <MdAdminPanelSettings />,
      t('roles'),
      <Link to="/roles">{t('roles')}</Link>,
    ),
    getItem(
      'users',
      <HiOutlineUserGroup />,
      t('users'),
      <Link to="/users">{t('users')}</Link>,
    ),
    getItem(
      'categories',
      <HiOutlineUserGroup />,
      t('categories'),
      <Link to="/categories">{t('categories')}</Link>,
    ),
    // getItem(
    //   'subscriptions',
    //   <GiPriceTag />,
    //   t('subscriptions'),
    //   <Link to="/dashsubscriptions">{t('subscriptions')}</Link>,
    // ),
  ];

  if (roles?.includes('admin')) {
    // Include adminMenuItems before other menu items
    menuItems = menuItems.concat(adminMenuItems);
  }

  menuItems.push(
    // getItem(
    //   'profile',
    //   <HiUser />,
    //   t('profile'),
    //   <Link to="/profile">{t('profile')}</Link>,
    // ),
    getItem(
      'posts',
      <HiDocumentText />,
      t('posts'),
      <Link to="/posts">{t('posts')}</Link>,
    ),
    // getItem(
    //   'orders',
    //   <MdAttachMoney />,
    //   t('my-subscriptions'),
    //   <Link to="/orders">{t('my-subscriptions')}</Link>,
    // ),
    getItem(
      'administration',
      <LuCrown />,
      t('administration'),
      t('administration'),
      [
        // getItem(
        //   'settings',
        //   <CiSettings />,
        //   t('settings'),
        //   <Link to="/settings">{t('settings')}</Link>,
        // ),
        {
          key: 'signOut',
          icon: <HiArrowSmRight />,
          label: t('sign-out'),
          onClick: handleSignout,
        },
      ],
    ),
  );

  return (
    <>
      {userId && window.innerWidth < 768 && (
        <Dropdown style={{ marginLeft: 10 }} menu={{ items: menuItems }}>
          <button
            style={{
              width: 'fit-content',
              position: 'fixed',
              top: '25px',
              zIndex: 999,
              left: '20px',
            }}
          >
            <IoMenu style={{ fontSize: '1.5rem' }} />
          </button>
        </Dropdown>
      )}
      {userId && window.innerWidth > 768 && (
        <Sider
          collapsible
          className="site-layout-background  "
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
          // trigger={null}
          style={{
            overflow: 'auto',
            position: 'sticky',
            top: 70,
            left: 0,
            height: '100vh',
            zIndex: 99,
          }}
        >
          {' '}
          <Menu
            mode="inline"
            defaultSelectedKeys="dashboard"
            items={menuItems}
          />
        </Sider>
      )}
    </>
  );
}
